import * as React from "react";
import { privacyContainer, privacyHeader } from "./Privacy.module.scss";

export const PrivacyContainer = (props: {children:React.ReactNode}) => {
    return (<div className={privacyContainer}>{props.children}</div>);
};

export const PrivacyHeader = (props : {children:React.ReactNode}) =>{
    return(<h1 className={`text-center ${privacyHeader}`}>{props.children}</h1>);
};


