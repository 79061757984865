import * as React from "react";
import { graphql } from "gatsby";
import { IPrivacyProps } from "../pageHelpers/Privacy/PrivacyProps";
import { Constants } from "../@types/Constants";
import { getParagraph } from "../utils/paragraphHelpers";
import Layout from "../components/global/Layout/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {PrivacyContainer, PrivacyHeader } from "../pageHelpers/Privacy/PrivacyHelpers";
import SEO from "../components/global/SEO/SEO";

const Privacy = (props: IPrivacyProps) => {
  const privacy = props.data.page;
  const paragraphs = props.data.page.relationships.paragraphs.map(getParagraph);
  const _pageBanner = paragraphs.filter((p: any) => { return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER : false; });
  return (<Layout >
    <SEO title={privacy?.title} description={privacy?.field_meta_description} />
    {_pageBanner}
    <Container>
      <Row>
        <Col>
          {privacy !== null &&
            <React.Fragment>
              <PrivacyContainer>
              <div dangerouslySetInnerHTML={{ __html: privacy.body?.processed }} />
              </PrivacyContainer>

            </React.Fragment>
          }
        </Col>
      </Row>
    </Container>
  </Layout>);
};
export const pageQuery = graphql`
  query PrivacyQuery  {
    page: nodePage(path: { alias: { eq: "/v1/privacy" } }) {
      id
      title
      field_meta_description
      path {
        alias
      }
      body {
        processed
        value
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
        }
      }
    }
  }
`;
export default Privacy;